import React from 'react'
import ReactDOM from 'react-dom'
import toastr from 'toastr'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'toastr/build/toastr.css'
import './css/index.css'
import ReduxApp from './redux/ReduxApp'
import reportWebVitals from './reportWebVitals'

toastr.options = {
  closeButton: false,
  debug: false,
  progressBar: true,
  positionClass: 'toast-bottom-right',
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxApp />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
