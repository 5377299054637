import { Button, Offcanvas } from 'react-bootstrap'
import { ReactComponent as Logo } from '../../../static/images/logo.svg'
import { ReactComponent as LogoV } from '../../../static/images/logo-v.svg'
import { MdArrowBack, MdInfo } from 'react-icons/md'
import './css/navbar.css'
import { useCallback, useMemo, useState } from 'react'
import VertricalCodingChallenge from './VertricalCodingChallenge'
import SearchBar from '../SearchBar/SearchBar'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const NavBar: React.FC = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const [showAbout, setShowAbout] = useState<Boolean>(false)

    const showSearchBar = useMemo(() => location.pathname === '/search', [location])
    const showGoBackButton = useMemo(() => location.pathname?.includes('/item'), [location])

    const handleShowAbout = useCallback(() => {
        setShowAbout(true)
    }, [setShowAbout])

    const handleHideAbout = useCallback(() => {
        setShowAbout(false)
    }, [setShowAbout])

    const handleGoBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <div className='navbar'>
            <div className='navbar-left'>
                <Link to='/' >
                {
                    showSearchBar ? <LogoV className='logo' /> : <Logo className='logo' />
                }
                </Link>
                {
                    showGoBackButton && (
                        <Button
                            variant='default'
                            size='sm'
                            title='Go back'
                            className='go-back'
                            onClick={handleGoBack}
                        >
                            <MdArrowBack />
                        </Button>
                    )
                }
                {
                    showSearchBar && (
                        <SearchBar dontAnimate={true} />
                    )
                }
            </div>
            <div className='navbar-buttons'>
                {/* <Dropdown>
                    <Dropdown.Toggle
                        as={NavButton}
                    >
                        <MdSettings />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            Spanish
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle
                        as={NavButton}
                    >
                        <MdLanguage />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            Spanish
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                <Button
                    variant='default'
                    onClick={handleShowAbout}
                >
                    <MdInfo />
                </Button>
                <Offcanvas
                    show={showAbout}
                    placement='end'
                    onHide={handleHideAbout}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><h1>Vertrical Coding Challenge</h1></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <VertricalCodingChallenge />    
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    )
}

export default NavBar