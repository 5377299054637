import produce from 'immer'
import { ActionType } from '../action-types'
import { Action } from '../actions'

export interface searchState {
    search: string;
    loading: boolean;
}

const initState: searchState = {
    search: '',
    loading: false
}

const searchReducer = (state: searchState = initState, action: Action) => 
    produce(state, (draft) => {
        switch (action.type) {
            case ActionType.SET_LOADING:
                draft.loading = action.loading
                return
            case ActionType.SET_SEARCH_VALUE:
                draft.search = action.value
                return
            default:
                return state
        }
    })

export default searchReducer