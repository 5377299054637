import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import mainReducer from './reducers'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const middleware = [
    thunk
]

const composeEnhancers = (
    (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose

const enhancer = composeEnhancers(applyMiddleware(...middleware))

const store = createStore(
    mainReducer,
    enhancer
)

export default store