import { combineReducers } from "redux"
import search, { searchState } from "../../components/shared/SearchBar/reducers"

export interface IRootState {
    search: searchState
}

const mainReducer = combineReducers<IRootState>({
    search
})

export default mainReducer