import { createSelector } from 'reselect'
import { IRootState } from '../../../../redux/reducers'

const getSearchState = (state: IRootState) => state.search

export const getSearch = createSelector(
    getSearchState,
    (state) => state.search
)

export const getLoading = createSelector(
    getSearchState,
    (state) => state.loading
)