const VertricalCodingChallenge = () => {
    return (
      <div>
        <h3>General information</h3>
        <hr />
        <p>
            <b>Task:</b> Build a search engine
            <br />
            <b>Tech Stack:</b> Typescript, React, Node.js
            <br />
            <b>Time frame:</b> 3 days
        </p>
        <h3>Requirements specification</h3>
        <hr />
        <ol>
            <li>
                <span>We have a search field with a button</span>
                <ol>
                    <li>the search gets triggered when the search button is clicked</li>
                    <li>the search gets triggered when you press Enter/Return</li>
                </ol>
            </li>
            <li>
                <span>The backend should be built using a Node.js framework of your choice</span>
                <ol>
                    <li>
                        You can use a hard coded array of object for the search result
                        <ul><li>Bonus: you can have an actual database of your choice (SQL or NoSQL) with a sample data set and describe how to use it in the project</li></ul>
                    </li>
                    <li>
                        The objects should have following structure:<br />
                        <code>
                            &#123;<br />
                            &emsp;&emsp;title: String,<br />
                            &emsp;&emsp;photo: String,<br />
                            &emsp;&emsp;description: String,<br />
                            &emsp;&emsp;short_description: String<br />
                            &#125;
                        </code>
                    </li>
                </ol>
            </li>
            <li>Once the search is triggered it should search objects on the server by title</li>
            <li>The search results should be displayed on the page (no pagination needed)</li>
            <li>
                <span>The result should be styled as a flexbox (max-width is free of choice but not full screen width)</span>
                <ol>
                    <li>the left column should contain a resized photo (thumbnail)</li>
                    <li>the right column should contain a title and short description</li>
                    <li>Columns should have proportion of 20-80%</li>
                </ol>
            </li>
            <li>The title should link to a page which contains a full-sized photo and description, the page should also have a link to go back to the search results</li>
            <li>Setup a basic code testing structure as a placeholder where real test would go, using any testing utility of your choice</li>
        </ol>
        <p>
            Routing should alse be done on the client side using React.<br />
            <br />
            You're free to choose a routing library or implement your own router.<br />
            You're free to choose how you'll implement paths both on the frontend and the backend.<br />
            <br />
            Code should be written using Typescript. You can also use <b>any</b> or <b>unknown</b> types.
        </p>
      </div>
    )
}

export default VertricalCodingChallenge