import Spinner from 'react-bootstrap/Spinner'

const Loading = () => {
    return (
        <div 
            className='full-center'
            style={{
                position: 'absolute',
                left: '0',
                top: '0',
                backgroundColor: '#1111116f',
                color: '#fff'
            }}
        >
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>           
        </div>
    )
}

export default Loading
