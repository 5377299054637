import { Suspense, lazy } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Loading from "./custom/Loading"
import NavBar from "./shared/NavBar/NavBar"

const Search = lazy(() => import('./routes/Search/Search'))
const Results = lazy(() => import('./routes/Results/Results'))
const Item = lazy(() => import('./routes/Item/Item'))

const App = () => {

  return (
    <BrowserRouter>
      <NavBar />
      <div className="content">
        <Suspense fallback={<Loading />} >
          <Routes>
            <Route path='/' element={<Search />} />
            <Route path='/search' element={<Results />} />
            <Route path='/item/:itemId' element={<Item />} />
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  )
}

export default App