import { ActionType } from "../action-types";

export interface SearchAction {
    type: ActionType.SET_SEARCH_VALUE;
    value: string;
}

export interface LoadingAction {
    type: ActionType.SET_LOADING;
    loading: boolean;
}

export const setLoading = (loading: boolean): LoadingAction => {
    return {
        type: ActionType.SET_LOADING,
        loading
    }
}

export const setSearchValue = (value: string): SearchAction => {
    return {
        type: ActionType.SET_SEARCH_VALUE,
        value
    }
}

export type Action = SearchAction | LoadingAction
